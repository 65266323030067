@import "./vars/vars";
@import "./globals/globals";
@import "loaders";

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

button,
a,
input,
textarea {
  &:focus {
    outline: none; // Remove the outline for mouse clicks
  }

  &:focus-visible {
    $size: 2px;
    $type: dashed;
    outline: $size $type $orange;
    outline-offset: $size;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
div,
ul,
li,
i,
b,
strong,
img,
svg {
  color: $text-color;
}

:root {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: $background-color;
  position: absolute;
}

.page {
  margin-top: $header-height;
}

@import "./header/header";
@import "./errors/error_page.scss";
@import "page-connector";

button {
  &:focus,
  &:active,
  &:target {
    outline: none; // Ensuring that focus does not show for mouse
  }

  &:focus-visible {
    $size: 2px;
    $type: dashed;
    outline: $size $type $orange;
    outline-offset: $size;
  }
}
