nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: $header-height;
  left: 0;
  top: 0;
  position: fixed;
  $rgb: 25;
  background: rgba($rgb, $rgb, $rgb, 0.9);
  backdrop-filter: blur(10px);
  z-index: 99;
  $rgb: 55;
  border-bottom: 1px solid rgb($rgb, $rgb, $rgb);
  header.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    .nav-toggle {
      width: 35px;
      cursor: pointer;
      gap: 5px;
      &:hover {
        .bar {
          background: rgb(200, 200, 200);
        }
      }
      .bar {
        transition: 100ms ease all;
        height: 5px;
        background: white;
        margin-bottom: 5px;
        border-radius: 5px;
      }
    }
    .nav-links {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 30px;
      list-style: none;
      &-mobile {
        display: none;
        &.open {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          flex-wrap: nowrap;
        }
      }
      .link-wrapper {
        a {
          color: rgb(250, 250, 250);
          text-decoration: none;
          &:hover {
            color: $orange;
          }
        }
      }
    }
    .actions {
      .actions-button {
        background: $orange;
        transition: 100ms ease all;
        cursor: pointer;
        padding: 10px 20px;
        color: $black;
        outline: 2px dashed transparent;
        border: none;
        &:hover {
          background: $orange-dark;
        }
        &:focus-visible {
          $size: 2px;
          $type: dashed;
          outline: $size $type $orange;
          outline-offset: $size;
        }
      }
    }
  }
}
