.page.home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .section {
      &.head {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        height: -moz-fit-content;
        max-height: 400px;
        position: relative;
        z-index: 10;

        .title-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          min-height: 300px;
          z-index: +2;

          .title {
            text-align: center;
            font-size: 3em;
          }

          .description {
            text-align: center;
            font-size: 1.5em;
          }
        }

        .background {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          overflow: hidden;
          z-index: -1;

          .image {
            display: flex;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            object-fit: cover;
          }
          .gradient {
            $rgb: 25;
            &-1 {
              width: 100%;
              height: 100%;
              background: linear-gradient(
                0deg,
                rgba($rgb, $rgb, $rgb, 1) 0%,
                rgba($rgb, $rgb, $rgb, 0.5) 50%,
                rgba($rgb, $rgb, $rgb, 0.8) 75%,
                rgba($rgb, $rgb, $rgb, 1) 100%
              );
              z-index: 99;
              position: absolute;
            }
          }
        }
      }
      &.skills {
        width: 100%;
        border-top: 1px solid $grey-light;
        border-bottom: 1px solid $grey-light;
        .skills-wrapper {
          width: 100%;
          .skills {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            list-style: none;
            .skill {
              img {
                height: 50px;
                filter: grayscale(100%);
                opacity: 0.4;
                transition: 100ms ease all;
                &:hover {
                  transform: scale(1.2);
                  filter: grayscale(0);
                  opacity: 0.6
                }
              }
            }
          }
        }
      }
    }
  }
}
